<template>
  <div style="position: relative">
    <div class="operate">
      <el-page-header
        :content="$t('addProject.title')"
        @back="goBack"
      />
      <div class="headOperate">
        <el-button
          type="primary"
          @click="confirmAddProject"
        >
          {{ $t("common.confirmAdd") }}
        </el-button>
        <el-button
          type="primary"
          @click="addItem"
        >
          {{ $t("addProject.addStream") }}
        </el-button>
        <el-button
          type="primary"
          style="margin: 0 10px"
          @click="copyConfig"
        >
          {{ $t("addGroup.clone") }}
        </el-button>
        <el-select
          v-model="copyProject"
          :popper-append-to-body="false"
          :placeholder="$t('config.copyProject')"
        >
          <el-option
            v-for="it in projectOptions"
            :key="it"
            :label="it"
            :value="it"
          />
        </el-select>
      </div>
    </div>
    <div class="card">
      <el-card
        v-if="globalCofig"
        class="card_1"
      >
        <div slot="header">
          {{ $t("editProject.global_configs") }}
        </div>
        <el-form
          ref="globalConfig"
          :model="globalCofig"
          inline
          :rules="globalRules"
          label-width="160px"
        >
          <el-form-item
            :label="$t('addProject.projectName')"
            prop="projectName"
          >
            <el-input v-model="globalCofig.projectName" />
          </el-form-item>
          <div>
            <el-form-item
              :label="$t('config.psl_verbose_log')"
              prop="psl_verbose_log"
            >
              <el-select
                v-model="globalCofig.psl_verbose_log"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="radio in pslVerboseLogList"
                  :key="radio.option_value"
                  :value="Number(radio.option_value)"
                  :label="radio.option_label"
                />
              </el-select>
            </el-form-item>
            <el-tooltip
              :content="$t('editProject.logsize_count_range')"
              effect="light"
              placement="top"
            >
              <el-form-item
                :label="$t('addProject.psl_logsize_size')"
                prop="psl_logsize_size"
              >
                <el-input v-model.number="globalCofig.psl_logsize_size" />
              </el-form-item>
            </el-tooltip>
            <el-form-item
              :label="$t('addProject.psl_logsize_count')"
              prop="psl_logsize_count"
            >
              <el-input v-model.number="globalCofig.psl_logsize_count" />
            </el-form-item>
          </div>
          <div>
            <el-form-item
              :label="$t('config.ptcp_log')"
              prop="ptcp_log"
            >
              <el-select
                v-model="globalCofig.ptcp_log"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="radio in ptcpLogList"
                  :key="radio.option_value"
                  :value="Number(radio.option_value)"
                  :label="radio.option_label"
                />
              </el-select>
            </el-form-item>
            <el-tooltip
              :content="$t('editProject.logsize_count_range')"
              effect="light"
              placement="top"
            >
              <el-form-item
                :label="$t('addProject.ptcp_logsize_size')"
                prop="ptcp_logsize_size"
              >
                <el-input v-model.number="globalCofig.ptcp_logsize_size" />
              </el-form-item>
            </el-tooltip>
            <el-form-item
              :label="$t('addProject.ptcp_logsize_count')"
              prop="ptcp_logsize_count"
            >
              <el-input v-model.number="globalCofig.ptcp_logsize_count" />
            </el-form-item>
          </div>
          <div>
            <el-form-item
              :label="$t('addProject.pzvt_refresh_interval')"
              prop="pzvt_refresh_interval"
            >
              <el-input v-model.number="globalCofig.pzvt_refresh_interval" />
            </el-form-item>
            <el-tooltip
              content="eg: {width: 1080}"
              effect="light"
              placement="top"
            >
              <el-form-item :label="$t('addProject.expandParam')">
                <el-input
                  v-model="globalCofig.extension_params"
                  style="width: 410px"
                  type="textarea"
                />
              </el-form-item>
            </el-tooltip>
          </div>
          <div>
            <el-form-item
                :label="$t('addProject.compatible_oloversion_setting')"
                prop="compatible_oloversion_setting"
            >
              <el-checkbox
                  v-model="globalCofig.compatible_checked"
                  :true-label="1"
                  :false-label="0"
              />
            </el-form-item>
          </div>
        </el-form>
      </el-card>
      <el-card class="card_2">
        <div slot="header">
          {{ $t("editProject.streaming_configs") }}
        </div>
        <el-collapse v-model="activeNames">
          <el-collapse-item
            v-for="(item, index) in streamingConfigs"
            :key="index"
            :name="`Stream_${index}`"
          >
            <template slot="title">
              <span style="margin-right: 20px">{{ `Stream_${index}` }}</span>
              <el-button
                type="primary"
                @click.stop="handleCopyStream(index)"
              >
                {{ $t("editProject.copyStream") }}
              </el-button>
              <el-button
                v-if="index != 0"
                type="danger"
                @click.stop="deleteItem(index)"
              >
                {{ $t("common.delete") }}
              </el-button>
            </template>
            <el-card
              :header="$t('editProject.base')"
              class="cardStyle"
            >
              <el-form
                ref="base_param"
                :model="item.base_param"
                label-width="210px"
                inline
                :rules="base_paramRules"
              >
                <div class="interval">
                  <el-form-item
                    label="Group"
                    prop="group"
                  >
                    <el-select
                      v-model="item.base_param.group"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="it in groupOptions"
                        :key="it"
                        :label="it"
                        :value="it"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="Profile"
                    prop="profile"
                  >
                    <el-select
                      v-model="item.base_param.profile"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="it in profileOptions[item.base_param.group]"
                        :key="it"
                        :label="it"
                        :value="it"
                      />
                    </el-select>
                  </el-form-item>
                </div>
                <div class="interval">
                  <el-form-item
                    :label="$t('editProject.working_mode')"
                    prop="working_mode"
                  >
                    <el-select
                      v-model="item.base_param.working_mode"
                      :popper-append-to-body="false"
                      @change="changeWorkingMode(item)"
                    >
                      <el-option
                        v-for="op in workingModeOptions"
                        :key="op.option_value"
                        :value="op.option_value"
                        :label="op.option_label"
                      />
                    </el-select>
                  </el-form-item>
                  <el-tooltip
                    content="eg: 10"
                    effect="light"
                    placement="top"
                  >
                    <el-form-item
                      :key="item.base_param.working_mode+index+'mode'"
                      :label="$t('addProject.customizeMode')"
                      prop="customize_working_mode"
                      :rules="customize_working_modeIsRequire(item.base_param.working_mode)"
                    >
                      <el-input
                        v-model.number="item.base_param.customize_working_mode"
                        :disabled="item.base_param.working_mode!='customize'"
                      />
                    </el-form-item>
                  </el-tooltip>
                </div>
                <div class="interval">
                  <el-form-item
                      :label="$t('editProject.push_num')"
                      prop="push_num"
                  >
                    <el-select
                        v-model="item.base_param.enable_push"
                        :popper-append-to-body="false"
                    >
                      <el-option
                          v-for="item in enable_pushOptions"
                          :key="item.option_value"
                          :value="item.option_value"
                          :label="item.option_label"
                      />
                    </el-select>
                  </el-form-item>
                  <el-tooltip
                    content="eg: crs=1"
                    effect="light"
                    placement="top"
                  >
                    <el-form-item :label="$t('editProject.CustomAcquisitionParameters')">
                      <el-input v-model="item.base_param.custom_push_url_args" />
                    </el-form-item>
                  </el-tooltip>
                </div>
                <div class="interval">
                  <el-tooltip
                      content="eg: 123"
                      effect="light"
                      placement="top"
                  >
                    <el-form-item :label="$t('editProject.customized')">
                      <el-input v-model="item.base_param.customized" />
                      <div class="customizedCaption">
                        {{ $t("addProject.customRemind") }}
                      </div>
                    </el-form-item>
                  </el-tooltip>
                </div>
                <div class="interval">
                  <el-tooltip
                    content="eg: {width: 1080}"
                    effect="light"
                    placement="top"
                  >
                    <el-form-item :label="$t('addProject.expandParam')">
                      <el-input
                        v-model="item.base_param.extension_params"
                        style="width: 680px"
                        type="textarea"
                      />
                    </el-form-item>
                  </el-tooltip>
                  <!-- <el-form-item :label="$t('editProject.advance')">
                    <el-input v-model="item.base_param.ADV_Param"></el-input>
                  </el-form-item> -->
                </div>
              </el-form>
            </el-card>
            <el-card
              :header="$t('editProject.video_capture_param')"
              class="cardStyle"
            >
              <el-form
                ref="video_capture_param"
                :model="item.video_capture_param"
                inline
                label-width="150px"
                :rules="video_capture_paramRules"
              >
                <el-form-item
                  :label="$t('addProject.impl')"
                  prop="impl"
                >
                  <el-tooltip
                      content="custom must be number"
                      effect="light"
                      placement="top"
                  >
                    <el-select
                      v-model.number="item.video_capture_param.impl"
                      :popper-append-to-body="false"
                      :placeholder="$t('project.inputCustom')"
                      @change="videoImplChange(item.video_capture_param.impl, index)"
                      :filterable="!implFilter(item.video_capture_param.impl)"
                      :allow-create="!implFilter(item.video_capture_param.impl)"
                    >
                      <el-option
                        v-for="it in videoImplOptions"
                        :key="it.option_value"
                        :disabled="streamingConfigs.length < 2 && it.option_value == 12003"
                        :value="it.option_value"
                        :label="it.option_label"
                      />
                    </el-select>
                  </el-tooltip>
                </el-form-item>
                <el-form-item
                  v-show="item.video_capture_param.impl==12003"
                  :label="$t('montage.title')"
                >
                  <div
                    class="watch"
                    @click="watchMontage(index)"
                  >
                    <span>{{ $t("editServer.watch") }}<i class="el-icon-view el-icon--right" /></span>
                  </div>
                </el-form-item>
                <!-- <el-form-item
                  v-show="false"
                  :key="item.video_capture_param.impl+index+'impl'"
                  :label="$t('addProject.implCustom')"
                  prop="customize_impl"
                  :rules="customFieldIsRequire(item.video_capture_param.impl)"
                >
                  <el-input v-model.number="item.video_capture_param.customize_impl" />
                </el-form-item> -->
                <el-form-item
                  :label="$t('addProject.format')"
                  prop="format"
                  :rules="formatIsRequire(item.video_capture_param.impl)"
                >
                  <el-tooltip
                      content="custom must be number"
                      effect="light"
                      placement="top"
                  >
                    <el-select
                      v-model="item.video_capture_param.format"
                      :placeholder="$t('project.inputCustom')"
                      :clearable="formatIsClear(item.video_capture_param.impl)"
                      :popper-append-to-body="false"
                      @change="videoFormatChange(item.video_capture_param.format, index)"
                      :filterable="!formatFilter(item.video_capture_param.format, index)"
                      :allow-create="!formatFilter(item.video_capture_param.format, index)"
                    >
                      <el-option
                        v-for="it in formatOptions[index]"
                        :key="it.option_value"
                        :value="it.option_value"
                        :label="it.option_label"
                      />
                    </el-select>
                  </el-tooltip>
                </el-form-item>
                <el-form-item
                  v-show="item.video_capture_param.impl != 2"
                  :label="$t('addProject.device')"
                  prop="device"
                  :rules="deviceIsRequire(item.video_capture_param.impl)"
                >
                  <el-select
                    v-model="item.video_capture_param.device"
                    :popper-append-to-body="false"
                    :placeholder="$t('project.inputCustom')"
                    @change="videoDeviceChange(item.video_capture_param.device, index)"
                    :filterable="!deviceFilter(item.video_capture_param.device, index)"
                    :allow-create="!deviceFilter(item.video_capture_param.device, index)"
                  >
                    <el-option
                      v-for="it in deviceOptions"
                      :key="it.option_value"
                      :value="it.option_value"
                      :label="it.option_label"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-show="item.video_capture_param.impl==2"
                  :label="$t('addProject.deviceIndex')"
                  prop="device_index"
                  :rules="deviceIndexIsRequire(item.video_capture_param.impl)"
                >
                  <el-select
                    v-model="item.video_capture_param.device_index"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="it in deviceIndexOptions"
                      :key="it.option_value"
                      :value="it.option_value"
                      :label="it.option_label"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="$t('addProject.fps')"
                  prop="fps"
                >
                  <el-input v-model.number="item.video_capture_param.fps" />
                </el-form-item>
                <el-form-item
                  :label="$t('addProject.res')"
                  prop="resolution"
                >
                  <el-select
                    v-model="item.video_capture_param.resolution"
                    :popper-append-to-body="false"
                    @visible-change="isVisiable => recordOriginRes(isVisiable, item.video_capture_param.resolution)"
                    @change="customRes(item.video_capture_param)"
                  >
                    <el-option
                      v-for="it in resOptions"
                      :key="it.option_value"
                      :value="it.option_value"
                      :label="it.option_label"
                    />
                  </el-select>
                </el-form-item>
                <el-tooltip
                  content="eg: EXC/libExternCapture"
                  effect="light"
                  placement="top"
                >
                  <el-form-item
                    v-show="item.video_capture_param.impl==12"
                    :label="$t('addProject.library')"
                  >
                    <el-input v-model="item.video_capture_param.library" />
                  </el-form-item>
                </el-tooltip>
                <el-tooltip
                  content="eg: {device_name:USB Aduio Device}"
                  effect="light"
                  placement="top"
                >
                  <el-form-item :label="$t('addProject.extern_param')">
                    <el-input
                      v-model="item.video_capture_param.extern_param"
                      style="width: 515px"
                      type="textarea"
                    />
                  </el-form-item>
                </el-tooltip>
                <el-tooltip
                  v-if="item.video_capture_param.impl==12001"
                  content="rtsp://127.0.0.1/live"
                  effect="light"
                  placement="top"
                >
                  <el-form-item
                    v-if="item.video_capture_param.impl==12001"
                    label="RTSP URL"
                    prop="rtsp_capturer_addr"
                    :rules="RTSP_URL_IsRequire(item.video_capture_param.impl)"
                  >
                    <el-input
                      v-model="item.video_capture_param.rtsp_capturer_addr"
                      style="width: 515px"
                      type="textarea"
                    />
                  </el-form-item>
                </el-tooltip>
                <el-tooltip
                  v-if="item.video_capture_param.impl==12002"
                  content="rtmp://127.0.0.1/live"
                  effect="light"
                  placement="top"
                >
                  <el-form-item
                    v-if="item.video_capture_param.impl==12002"
                    label="RTMP URL"
                    prop="rtmp_capturer_addr"
                    :rules="RTMP_URL_IsRequire(item.video_capture_param.impl)"
                  >
                    <el-input
                      v-model="item.video_capture_param.rtmp_capturer_addr"
                      style="width: 515px"
                      type="textarea"
                    />
                  </el-form-item>
                </el-tooltip>
                <el-tooltip
                  content="eg: {width:1920, height: 1080}"
                  effect="light"
                  placement="top"
                >
                  <el-form-item :label="$t('addProject.expandParam')">
                    <el-input
                      v-model="item.video_capture_param.extension_params"
                      style="width: 515px"
                      type="textarea"
                    />
                  </el-form-item>
                </el-tooltip>
              </el-form>
            </el-card>
            <el-card class="cardStyle">
              <div
                slot="header"
                class="layoutHeader"
              >
                <span>{{ $t('editProject.audio_capture_param') }}</span>
                <el-button
                  type="primary"
                  @click="addImpl(index)"
                >
                  {{ $t("common.add") }}
                </el-button>
              </div>
              <el-card
                v-for="(fm,idx) in item.audio_capture_param"
                :header="$t('editProject.audioTrack')+idx"
                :key="idx"
                style="margin-bottom: 10px; overflow: visible !important;"
              >
                <el-form
                  ref="audio_capture_param"
                  :model="fm"
                  inline
                  label-width="150px"
                  :rules="audio_capture_paramRules"
                >
                  <el-form-item
                    :label="$t('addProject.impl')"
                    prop="impl"
                  >
                    <el-select
                      v-model.number="fm.impl"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="i in audioImplOptions"
                        :key="i.option_value"
                        :value="Number(i.option_value)"
                        :label="i.option_label"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    :label="$t('editProject.audio_sample_rate')"
                    prop="audio_sample_rate"
                  >
                    <el-input v-model.number="fm.audio_sample_rate" />
                  </el-form-item>
                  <el-form-item
                    :label="$t('editProject.audio_channel_num')"
                    prop="audio_channel_num"
                  >
                    <el-input v-model.number="fm.audio_channel_num" />
                  </el-form-item>
                  <el-tooltip
                    content="eg: {width: 1080}"
                    effect="light"
                    placement="top"
                  >
                    <el-form-item :label="$t('addProject.expandParam')">
                      <el-input
                        v-model.number="fm.extension_params"
                        style="width: 515px"
                        type="textarea"
                      />
                    </el-form-item>
                  </el-tooltip>
                  <el-form-item
                    v-if="idx!=0"
                    label=" "
                  >
                    <el-button
                      type="danger"
                      @click="delImpl(index,idx)"
                    >
                      {{ $t("common.delete") }}
                    </el-button>
                  </el-form-item>
                </el-form>
              </el-card>
            </el-card>
            <el-card
              :header="$t('editProject.video_enc_param')"
              class="cardStyle"
            >
              <el-form
                ref="video_enc_param"
                :model="item.video_enc_param"
                inline
                label-width="150px"
              >
                <el-form-item :label="$t('editProject.colorSpace')">
                  <el-select
                    v-model="item.video_enc_param.color_space"
                    clearable
                    :popper-append-to-body="false"
                    @change="remindCutom"
                  >
                    <el-option
                      v-for="it in colorspaceOptions"
                      :key="it.option_value"
                      :value="it.option_value"
                      :label="it.option_label"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('editProject.video_enc_type')">
                  <el-select
                    v-model="item.video_enc_param.codec_name"
                    clearable
                    :popper-append-to-body="false"
                    @change="remindCutom"
                  >
                    <el-option
                      v-for="op in video_enc_paramOptions"
                      :key="op.option_value"
                      :value="op.option_value"
                      :label="op.option_label"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('editProject.encodingFormat')">
                  <el-select
                    v-model="item.video_enc_param.format"
                    clearable
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="op in video_enc_format_options"
                      :key="op.option_value"
                      :value="op.option_value"
                      :label="op.option_label"
                    />
                  </el-select>
                </el-form-item>
                <!-- <el-form-item :label="$t('editProject.customcolorSpace')">
                  <el-input v-model.number="item.video_enc_param.color_space_param" :disabled="item.base_param.color_space!=-1"></el-input>
                </el-form-item> -->
                <el-tooltip
                  content="eg: {width: 1080}"
                  effect="light"
                  placement="top"
                >
                  <el-form-item :label="$t('addProject.expandParam')">
                    <el-input
                      v-model="item.video_enc_param.extension_params"
                      style="width: 515px"
                      type="textarea"
                    />
                  </el-form-item>
                </el-tooltip>
              </el-form>
            </el-card>
            <el-card
              :header="$t('editProject.audio_enc_param')"
              class="cardStyle"
            >
              <el-form
                ref="audio_enc_param"
                :model="item.audio_enc_param"
                label-width="150px"
                inline
              >
                <el-tooltip
                  content="eg: {width: 1080}"
                  effect="light"
                  placement="top"
                >
                  <el-form-item :label="$t('addProject.expandParam')">
                    <el-input
                      v-model="item.audio_enc_param.extension_params"
                      style="width: 515px"
                      type="textarea"
                    />
                  </el-form-item>
                </el-tooltip>
              </el-form>
            </el-card>
          </el-collapse-item>
        </el-collapse>
      </el-card>
    </div>
    <!-- 自定义采集分辨率 -->
    <el-dialog
      :title="$t('addProject.dialogTitle')"
      :visible.sync="resDialog"
      width="600px"
      @opened="focusResWidth"
    >
      <el-form
        ref="resCustomForm"
        :model="resCustom"
        :rules="resRules"
        inline
      >
        <el-form-item
          :label="$t('addProject.width')"
          prop="width"
        >
          <el-input
            ref="resWidthInput"
            v-model="resCustom.width"
          />
        </el-form-item>
        <el-form-item
          :label="$t('addProject.height')"
          prop="height"
        >
          <el-input v-model="resCustom.height" />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancelAddRes">{{ $t("common.cancel") }}</el-button>
        <el-button
          type="primary"
          @click="addRes"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 拼接模式Dialog -->
    <el-dialog
      class="montage"
      fullscreen
      :title="$t('montage.title')"
      :visible.sync="montageDialog"
      @before-close="closeMontage"
    >
      <el-form
        ref="commonConfig"
        :model="commonConfig"
        :rules="commonConfigRules"
        inline
      >
        <el-form-item
          :label="$t('montage.output_param_index')"
          prop="output_param_index"
        >
          <el-select
            v-model.number="commonConfig.output_param_index"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="(item,index) in montageList"
              :key="index"
              :value="index"
              :label="index"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('montage.output_param_mode')"
          prop="output_param_mode"
        >
          <el-select
            v-model.number="commonConfig.output_param_mode"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in outputParamModeOptions"
              :key="item.option_value"
              :value="item.option_value"
              :label="item.option_label"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="confirmMontage"
          >
            {{ $t("common.confirm") }}
          </el-button>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        style="margin-bottom:20px"
        @click="addMontage"
      >
        {{ $t("common.add") }}
      </el-button>
      <el-table
        :border="true"
        :data="montageList"
      >
        <el-table-column
          :label="$t('montage.w')"
          prop="w"
          width="80"
          align="center"
        />
        <el-table-column
          :label="$t('montage.h')"
          prop="h"
          width="80"
          align="center"
        />
        <el-table-column
          label="x"
          prop="x"
          width="80"
          align="center"
        />
        <el-table-column
          label="y"
          prop="y"
          width="80"
          align="center"
        />
        <el-table-column
          label="z"
          prop="z"
          width="80"
          align="center"
        />
        <el-table-column
          :label="$t('editProject.streamIndex')"
          prop="streaming_config_index"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            {{ `Stream_${scope.row.streaming_config_index}` }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.operate')"
          header-align="center"
          width="160"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEditMontage(scope.row, scope.$index)"
            >
              {{ $t("common.edit") }}
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDeleteMontage(scope.$index)"
            >
              {{ $t("common.delete") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 内置添加拼接Dialog -->
      <el-dialog
        top="0px"
        :title="$t('montage.add')"
        :visible.sync="addMontageDialog"
        append-to-body
        width="800px"
      >
        <el-form
          ref="montageForm"
          label-width="140px"
          :model="montageForm"
          inline
          :rules="montageFormRules"
        >
          <el-form-item
            :label="$t('montage.w')"
            prop="w"
          >
            <el-input v-model.number="montageForm.w" />
          </el-form-item>
          <el-form-item
            :label="$t('montage.h')"
            prop="h"
          >
            <el-input v-model.number="montageForm.h" />
          </el-form-item>
          <el-form-item
            label="x"
            prop="x"
          >
            <el-input v-model.number="montageForm.x" />
          </el-form-item>
          <el-form-item
            label="y"
            prop="y"
          >
            <el-input v-model.number="montageForm.y" />
          </el-form-item>
          <el-form-item
            label="z"
            prop="z"
          >
            <el-input v-model.number="montageForm.z" />
          </el-form-item>
          <el-form-item
            :label="$t('editProject.streamIndex')"
            prop="streaming_config_index"
          >
            <el-select v-model="montageForm.streaming_config_index">
              <el-option
                v-for="(op,index) in streamingConfigs"
                :key="index"
                :disabled="op['video_capture_param']['impl'] === 12003"
                :value="index"
                :label="`Stream_${index}`"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="cancelAddMontage">{{ $t("common.cancel") }}</el-button>
          <el-button
            v-if="isAdd"
            type="primary"
            @click="confirmAddMontage"
          >{{ $t("common.confirm") }}</el-button>
          <el-button
            v-else
            type="primary"
            @click="confirmEditMontage"
          >{{ $t("common.confirm") }}</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { get_sename_list } from '@/api/api.js'
import {
  get_project_detail,
  get_all_groupName,
  add_project,
  get_profile_list,
  get_all_projectName,
  get_map_impl_format
} from '@/api/project'
import { validateRange, validateMin } from './validate.js'
export default {
  data () {
    this.formatOriginOptions = []
    return {
      video_enc_format_options: [],
      currentStreamConfigIndex: 0,
      montageDialog: false,
      addMontageDialog: false,
      montageList: [],
      outputParamModeOptions: [],
      commonConfig: {
        output_param_index: '',
        output_param_mode: ''
      },
      commonConfigRules: {
        output_param_index: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        output_param_mode: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      },
      colorspaceOptions: [],
      montageForm: {
        h: '',
        w: '',
        x: 0,
        y: 0,
        z: 0,
        streaming_config_index: null
      },
      montageFormRules: {
        w: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        h: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        x: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        y: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        z: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        streaming_config_index: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      },
      dialog_formatOptions: [],
      isAdd: true,
      activeNames: 'Stream_0',
      video_enc_paramOptions: [],
      formatOptions: [],
      videoImplOptions: [],
      audioImplOptions: [],
      deviceOptions: [],
      deviceIndexOptions: [],
      copyProject: '',
      projectOptions: [],
      workingModeOptions: [],
      globalCofig: null,
      streamingConfigs: [],
      groupOptions: [],
      profileOptions: [],
      enable_pushOptions: [],
      resOptions: [],
      resDialog: false,
      resCustom: {
        width: null,
        height: null
      },
      pslVerboseLogList: [],
      ptcpLogList: [],
      globalRules: {
        psl_logsize_count: [{ validator: validateMin(this), trigger: ['blur', 'change'] }],
        ptcp_logsize_count: [{ validator: validateMin(this), trigger: ['blur', 'change'] }],
        projectName: [{ required: true, message: this.$t('addProject.inputProject'), trigger: 'blur' }],
        psl_verbose_log: [{ required: true, message: this.$t('addProject.select_psl_verbose_log'), trigger: 'blur' }],
        ptcp_log: [{ required: true, message: this.$t('addProject.select_ptcp_log'), trigger: 'blur' }],
        ptcp_logsize_size: [{ validator: validateRange(this), trigger: ['blur', 'change'] }],
        psl_logsize_size: [{ validator: validateRange(this), trigger: ['blur', 'change'] }],
        pzvt_refresh_interval: [{ validator: validateMin(this), trigger: ['blur', 'change'] }]
      },
      base_paramRules: {
        group: [{ required: true, message: this.$t('addProject.select_group'), trigger: 'blur' }],
        profile: [{ required: true, message: this.$t('addProject.select_profile'), trigger: 'blur' }],
        working_mode: [{ required: true, message: this.$t('addProject.select_profile'), trigger: 'blur' }]
      },
      video_capture_paramRules: {
        impl: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        fps: [
          { required: true, message: this.$t('common.required'), trigger: 'blur' },
          { validator: validateMin(this), trigger: ['blur', 'change'] }
        ],
        resolution: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      },
      audio_capture_paramRules: {
        impl: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        audio_channel_num: [
          { required: true, message: this.$t('common.required'), trigger: 'blur' },
          { validator: validateMin(this), trigger: ['blur', 'change'] }
        ],
        audio_sample_rate: [
          { required: true, message: this.$t('common.required'), trigger: 'blur' },
          { validator: validateMin(this), trigger: ['blur', 'change'] }
        ]
      },
      resRules: {
        width: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        height: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      },
      audio_channel_num: [
        { required: true, message: this.$t('common.required'), trigger: 'blur' },
        { validator: validateMin(this), trigger: ['blur', 'change'] }
      ],
      audio_sample_rate: [
        { required: true, message: this.$t('common.required'), trigger: 'blur' },
        { validator: validateMin(this), trigger: ['blur', 'change'] }
      ],
      isMontage: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    implFilter(impl){
      return this.videoImplOptions.find((op) => {
        return impl == op.option_value
      })
    },
    formatFilter(format, index) {
      if(!this.formatOptions[index]) return
      return this.formatOptions[index].find((op) => {
        return format == op.option_value
      })
    },
    videoFormatChange(format, index) {
      if(format == 'customize') {
        this.streamingConfigs[index].video_capture_param.format = ''
      }
    },
    deviceFilter(deivce, index) {
      return this.deviceOptions.find((op) => {
        return deivce == op.option_value
      })
    },
    videoDeviceChange(device, index) {
      if(device == 'customize') {
        this.streamingConfigs[index].video_capture_param.device = ''
      }
    },
    cancelAddMontage () {
      this.addMontageDialog = false
      this.$refs.montageForm.clearValidate()
    },
    // 当采集工作模式选择自定义时，该项要求必填
    customize_working_modeIsRequire (value) {
      if (value === 'customize') {
        return [
          { required: true, message: this.$t('common.required'), trigger: 'blur' },
          { validator: validateMin(this), trigger: ['blur', 'change'] }
        ]
      }
      return []
    },
    // 当采集工作模式不为自定义时，清除自定义采集模式输入的值
    changeWorkingMode (item) {
      if (item.base_param.working_mode != 'customize') {
        item.base_param.customize_working_mode = ''
      }
    },
    // 格式化拼接模式表格中的自定义选项展示
    formatCustomize (value) {
      return value === 'customize' ? this.$t('common.customize') : '-'
    },
    // 自动聚焦
    focusResWidth () {
      this.$refs.resWidthInput.focus()
    },
    // 通过采集类型编码返回采集类型名称
    confirmImpl (code) {
      const res = this.videoImplOptions.find((item) => {
        return code == item.option_value
      })
      return res.option_label
    },
    // 如果数据格式存在除自定义以外的其他选项，则数据格式必选
    formatIsRequire (impl) {
      if (!impl) return []
      if (!this.implMap[impl] || impl === 'customize') return [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      return this.implMap[impl].length == 0 ? [] : [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
    },
    // 当数据格式不是必填时，数据格式可清除
    formatIsClear (impl) {
      if (!impl) return false
      if (!this.implMap[impl] || impl === 'customize') return true
      return this.implMap[impl].length == 0
    },
    // 设备名称是否必填
    deviceIsRequire (impl) {
      if (!impl) return []
      return impl == 2 ? [] : [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
    },
    // 设备编号是否必填
    deviceIndexIsRequire (impl) {
      if (!impl) return []
      return impl != 2 ? [] : [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
    },
    // 当值为自定义时，自定义字段必填
    customFieldIsRequire (format) {
      if (format != 'customize') return []
      return [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
    },
    // 采集类型是RTSP数据源则必填
    RTSP_URL_IsRequire (impl) {
      if (impl == 12001) return [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      return []
    },
    // 采集类型是RTMP数据源则必填
    RTMP_URL_IsRequire (impl) {
      if (impl == 12002) return [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      return []
    },
    remindCutom (value) {
      if (value == 'customize' || value == -1) {
        this.$message.info(this.$t('editProject.remindCutom'))
      }
    },
    // 查看拼接数据
    watchMontage (index) {
      this.isMontage = true
      this.montageDialog = true
      this.currentStreamConfigIndex = index
      const currentVideoCaptureParam = this.streamingConfigs[index].video_capture_param
      this.commonConfig.output_param_index = currentVideoCaptureParam.output_param_index
      this.commonConfig.output_param_mode = currentVideoCaptureParam.output_param_mode ? currentVideoCaptureParam.output_param_mode : 0
      this.montageList = currentVideoCaptureParam.splicing_pattern || []
    },
    // 关闭拼接模式dialog的回掉
    closeMontage () {
      this.isMontage = false
    },
    // 确认添加拼接模式
    confirmMontage () {
      this.$refs.commonConfig.validate((valid) => {
        if (valid) {
          this.streamingConfigs[this.currentStreamConfigIndex].video_capture_param.output_param_index = this.commonConfig.output_param_index
          this.streamingConfigs[this.currentStreamConfigIndex].video_capture_param.output_param_mode = this.commonConfig.output_param_mode
          this.streamingConfigs[this.currentStreamConfigIndex].video_capture_param.splicing_pattern = this.montageList
          this.montageDialog = false
          this.isMontage = false
        }
      })
    },
    // 添加拼接
    addMontage () {
      this.isAdd = true
      this.addMontageDialog = true
      this.montageForm = {
        h: 360,
        w: 640,
        x: 0,
        y: 0,
        z: 0,
        streaming_config_index: null
      }
    },
    confirmAddMontage () {
      this.$refs.montageForm.validate((valid) => {
        if (valid) {
          this.montageList.push(this.montageForm)
          this.addMontageDialog = false
        }
      })
    },
    confirmEditMontage () {
      this.$refs.montageForm.validate((valid) => {
        if (valid) {
          this.montageList.splice(this.editIndex, 1, this.montageForm)
          this.addMontageDialog = false
        }
      })
    },
    // 修改拼接
    handleEditMontage (row, index) {
      this.isAdd = false
      this.editIndex = index
      const backup = JSON.parse(JSON.stringify(row))
      this.montageForm = backup
      this.addMontageDialog = true
    },
    // 删除拼接
    handleDeleteMontage (index) {
      this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        this.montageList.splice(index, 1)
      }).catch(() => {})
    },
    // 复制流配置
    handleCopyStream (index) {
      const temp = JSON.parse(JSON.stringify(this.streamingConfigs[index]))
      this.streamingConfigs.push(temp)
      const len = this.streamingConfigs.length
      this.videoImplChange(temp.video_capture_param.impl, len - 1, false)
    },
    // 取消自定义采集分辨率
    cancelAddRes () {
      this.resDialog = false
      this.resCustom = {
        width: null,
        height: null
      }
      // 将自定义采集分辨率恢复成原始值
      this.currentCideoCapture.resolution = this.previousRes
      this.$refs.resCustomForm.resetFields()
    },
    // 自定义采集分辨率
    customRes (video_capture_param) {
      this.currentCideoCapture = video_capture_param
      if (video_capture_param.resolution === 1) {
        this.resDialog = true
      }
    },
    // 备份在选择另一个采集分辨率之前的值
    recordOriginRes (visiable, resolution) {
      if (visiable) {
        this.previousRes = resolution
      }
    },
    addRes () {
      this.$refs.resCustomForm.validate((valid) => {
        if (valid) {
          this.resDialog = false
          const res = this.resCustom.width + 'x' + this.resCustom.height
          this.resCustom = {
            width: null,
            height: null
          }
          this.currentCideoCapture.resolution = res
        }
      })
    },
    // 获取impl与format之间的对应关系
    async getMap () {
      const res = await get_map_impl_format()
      if (res.data.code === 0) {
        this.implMap = res.data.data
      }
    },
    addImpl (index) {
      const addData = {
        enable_push: 1,
        data_source: 2000,
        audio_channel_num: 1,
        audio_sample_rate: 48000,
        extension_params: '',
        impl: Number(this.audioImplOptions[1].option_value)
      }
      this.streamingConfigs[index].audio_capture_param.push(addData)
    },
    delImpl (index, idx) {
      this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        this.streamingConfigs[index].audio_capture_param.splice(idx, 1)
      }).catch(() => {})
    },
    // 采集类型变化后，数据格式的选项需要跟着变化
    // 当此处发生改变时，changeFormat应进行同步改变
    videoImplChange (item, index, isChange = true) {
      switch (item) {
        case 1:
        case 2:
        case 5:
        case 6:
        case 9:
        case 10:
        case 11:
          // 获取可用的数据格式编号数组
          console.log(item)
          const implMapArr = this.implMap[item] || []
          // 过滤出可用数据格式
          const filterArr = this.formatOriginOptions.filter((item) => {
            return implMapArr.includes(item.option_value)
          })
          // 替换掉原有数据格式
          filterArr.push({ option_label: this.$t('common.customize'), option_value: 'customize' })
          this.formatOptions.splice(index, 1, filterArr)
          break
        case 12:
        case 12001: // RTSP数据源
        case 12002: // RTMP数据源
        case 'customize': // 自定义，所有的数据格式均可用
        default:
          const options = JSON.parse(JSON.stringify(this.formatOriginOptions))
          options.push({ option_label: this.$t('common.customize'), option_value: 'customize' })
          // 更新对应流配置下的数据格式下拉选项
          this.formatOptions.splice(index, 1, options)
      }
      // 如果是修改采集类型引起的改变 则将对应流配置下的数据格式置空
      if (isChange) {
        if(this.streamingConfigs[index].video_capture_param.impl == 'customize') {
          this.streamingConfigs[index].video_capture_param.impl = ''
        }
        if (this.formatOptions[index].length <= 1) {
          this.streamingConfigs[index].video_capture_param.format = ''
        } else {
          this.streamingConfigs[index].video_capture_param.format = this.formatOptions[index][0]['option_value']
        }
      }
    },
    // 复制配置
    copyConfig () {
      if (this.copyProject) {
        this.getProjectDetail(this.copyProject)
      } else {
        this.$alert(this.$t('config.copyProject'), this.$t('common.remind'), {
          confirmButtonText: this.$t('common.confirm')
        })
      }
    },
    // 获取所有project名称列表
    async getAllProjectName () {
      const res = await get_all_projectName()
      if (res.data.code === 0) {
        this.projectOptions = res.data.data
      }
    },
    async getProfileList () {
      const res = await get_profile_list()
      if (res.data.code === 0) {
        this.profileOptions = res.data.data
      }
    },
    // 删除流配置
    deleteItem (index) {
      this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        this.streamingConfigs.splice(index, 1)
      }).catch(() => {})
    },
    // 添加流配置
    addItem () {
      const temp = JSON.parse(JSON.stringify(this.streamingConfigs[0]))
      this.streamingConfigs.push(temp)
      const len = this.streamingConfigs.length
      this.videoImplChange(temp.video_capture_param.impl, len - 1, false)
    },
    async getOptions (select_name, optionsList, customize) {
      const res = await get_sename_list({ select_name })
      if (res.data.code === 0) {
        this[optionsList] = res.data.data
        // 添加自定义选项
        customize && this[optionsList].push(customize)
      }
    },
    async selectList () {
      this.video_enc_paramOptions = this.getOptions('codec_name', 'video_enc_paramOptions', { option_label: this.$t('common.customize'), option_value: 'customize' })
      this.pslVerboseLogList = this.getOptions('psl_verbose_log', 'pslVerboseLogList')
      this.ptcpLogList = this.getOptions('ptcp_log', 'ptcpLogList')
      this.resOptions = this.getOptions('resolution', 'resOptions', { option_label: this.$t('common.customize'), option_value: 1 })
      this.videoImplOptions = this.getOptions('video_impl', 'videoImplOptions', { option_label: this.$t('common.customize'), option_value: 'customize' })
      this.audioImplOptions = this.getOptions('audio_impl', 'audioImplOptions')
      this.deviceOptions = this.getOptions('device', 'deviceOptions', { option_label: this.$t('common.customize'), option_value: 'customize' })
      this.deviceIndexOptions = this.getOptions('device_index', 'deviceIndexOptions')
      this.workingModeOptions = this.getOptions('working_mode', 'workingModeOptions', { option_label: this.$t('common.customize'), option_value: 'customize' })
      this.outputParamModeOptions = this.getOptions('output_param_mode', 'outputParamModeOptions')
      this.colorspaceOptions = this.getOptions('color_space', 'colorspaceOptions', { option_label: this.$t('common.customize'), option_value: -1 })
      this.video_enc_format_options = this.getOptions('video_enc_format', 'video_enc_format_options')
      this.enable_pushOptions = this.getOptions('enable_push', 'enable_pushOptions')
    },
    // 对所有表单进行校验
    checkForm () {
      this.checkFormResult = []
      this.$refs.globalConfig.validate((valid) => {
        this.checkFormResult.push(valid)
      })
      this.$refs.base_param.forEach((item) => {
        item.validate((valid) => {
          this.checkFormResult.push(valid)
        })
      })
      this.$refs.video_capture_param.forEach((item) => {
        item.validate((valid) => {
          this.checkFormResult.push(valid)
        })
      })
      this.$refs.audio_capture_param.forEach((item) => {
        item.validate((valid) => {
          this.checkFormResult.push(valid)
        })
      })
      this.$refs.video_enc_param.forEach((item) => {
        item.validate((valid) => {
          this.checkFormResult.push(valid)
        })
      })
      this.$refs.audio_enc_param.forEach((item) => {
        item.validate((valid) => {
          this.checkFormResult.push(valid)
        })
      })
    },
    // 确认添加project
    async confirmAddProject () {
      this.checkForm()
      const result = this.checkFormResult.every((item) => {
        return item === true
      })
      console.log(result)
      if (result) {
        const param = {
          project_name: this.globalCofig.projectName,
          project: {
            global_configs: this.globalCofig,
            streaming_configs: this.streamingConfigs
          }
        }
        const res = await add_project(param)
        if (res.data.code === 0) {
          this.$message.success(this.$t('config.addgroupSuccess'))
          this.$router.push('/project/index')
        }
      } else {
        this.$alert(this.$t('common.check'), this.$t('common.remind'), {
          confirmButtonText: this.$t('common.confirm')
        })
      }
    },
    async getProjectDetail (project) {
      const res = await get_project_detail({ project })
      if (res.data.code === 0) {
        const projectDetail = res.data.data
        this.globalCofig = projectDetail.global_configs ?? {}
        this.streamingConfigs = projectDetail.streaming_configs ?? {}
        const len = this.streamingConfigs.length
        // 数据格式的值以及选项取决于采集类型
        const result = await get_sename_list({ select_name: 'format' })
        if (result.data.code === 0) {
          this.formatOriginOptions = result.data.data
          for (let i = 0; i < len; i++) {
            this.videoImplChange(this.streamingConfigs[i].video_capture_param.impl, i, false)
          }
        }
      }
    },
    async getAllGroupName () {
      const res = await get_all_groupName()
      if (res.data.code === 0) {
        this.groupOptions = res.data.data
      }
    },
    goBack () {
      this.$router.back()
    },
    init () {
      this.selectList()
      this.getMap()
      this.getAllProjectName()
      this.getProfileList()
      this.getProjectDetail('template')
      this.getAllGroupName()
    }
  }
}
</script>

<style lang='scss' scoped>
.operate {
  /* display: flex; */
  justify-content: space-between;
  position: sticky;
  background: #fff;
  padding-bottom: 10px;
  margin-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #DCDFE6;
  top: 0;
  left: 0;
  z-index: 100;
  .headOperate {
    margin-top: 10px;
  }
}
.card_1 /deep/ .el-input {
  width: 120px;
}
.card_1 /deep/ .el-select {
  width: 120px;
}
.card {
  .card_1, .card_2 {
    margin-top: 20px;
    overflow: visible !important;
  }
}
.video_capture_paramCard {
  width: 360px;
  height: 100%;
  margin-left: 22px;
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: visible !important;
}
.audio_capture_paramCard {
  width: 400px;
  height: 100%;
  /* margin-left: 82px; */
  /* margin-right: 20px; */
  margin-bottom: 20px;
}
.customizedCaption {
  color: #909399;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
}
.watch {
  width: 177px;
  cursor: pointer;
  :hover{
    color: #3c8dbc;
  }
}
.montage /deep/ .el-dialog__headerbtn {
  font-size: 24px;
}

.interval {
  width: 900px;
  display: flex;
  justify-content: space-between;
}
.layoutHeader {
  display: flex;
  justify-content: space-between;
}
.cardStyle {
  margin-top: 10px;
  overflow: visible !important;
}
</style>
