// 验证是否处于指定区间内
export function validateRange (self, max = 1000, min = 0) {
  return (rule, value, cb) => {
    if (value === '') {
      cb()
      return
    }
    if (!isInteger(value)) {
      cb(new Error(self.$t('form.integer')))
      return
    }
    if (value < min) {
      cb(new Error(self.$t('form.min') + min))
    } else if (value > max) {
      cb(new Error(self.$t('form.max') + max))
    } else {
      cb()
    }
  }
}
// 验证是否小于最小值
export function validateMin (self, min = 0) {
  return (rule, value, cb) => {
    if (value === '') {
      cb()
      return
    }
    if (!isInteger(value)) {
      cb(new Error(self.$t('form.integer')))
      return
    }
    if (value < min) {
      cb(new Error(self.$t('form.min') + min))
    } else {
      cb()
    }
  }
}

// 验证是否大于最大值
export function validateMax (self, max = 1000) {
  return (rule, value, cb) => {
    if (value === '') {
      cb()
      return
    }
    if (!isInteger(value)) {
      cb(new Error(self.$t('form.integer')))
      return
    }
    if (value > max) {
      cb(new Error(self.$t('form.max') + max))
    } else {
      cb()
    }
  }
}

export function validateIsInteger (self) {
  return (rule, value, cb) => {
    if (value === '') {
      cb()
      return
    }
    if (!isInteger(value)) {
      cb(new Error(self.$t('form.integer')))
      return
    }
    cb()
  }
}

function isInteger (value) {
  if (typeof value !== 'number') {
    return false
  }
  if (value % 1 != 0) return false
  return true
}
